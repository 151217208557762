@import "utilities/variables";
@import "buttons";

.onboarding-container {
    .confetti {
        position: fixed;
        top: 0;
        left: 0;
    }
    .large-font {
        p {
            font-size: 50px !important;
        }
    }
    
    p {
        font-size: $paragraph-fs;
    }
    .page-container {
        max-width: 500px;
        margin: auto;
    }
    @media (max-width: 789px) {
        padding: 5% 5px !important;

    }
    @media (max-width: 1053px) {
        padding: 5% 10%;

    }
    padding: 5% 20%;

    // title
    h1 {
        font-size: $title-fs;
        font-weight: 550;
    }
    // subtitle
    h2 {
        font-size: $subtitle-fs;
        color: rgb(94, 94, 94);
        font-weight: normal;
        margin-bottom: 15px;
    }
    // formfield labels
    h3 {
        font-size: $formfield-labels-fs;
        margin-top: 10px;
        font-weight: 500;
        
    }
    p {
        line-height: 24px;
    }



    
    


    // progress bar
    .onboarding-progresss-bar {
        margin: auto;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        padding: 3% 0%;
        text-align: center;
        position: relative;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-radius: $border-radius $border-radius 0 0;

        .zoom-button {
            margin-right: 0;
            margin-top: -3px;
            max-width: 100px;
            position: absolute;
            right: 0;
            @media (max-width: 520px) {
                display: none;
            }
        }
    }

    .progress-ball {
        height: 28px;
        width: 28px;
        background: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        text-align: center;
        color: white;
        padding-top: 0%;
    }
    .h-line {
        flex-grow: 1;
        width: 10%;
        height: 3px;
        background: $primary-color;
        display: inline-block;
        vertical-align: middle;
        transform: translateY(21%);
    }
    .inactive-progress {
        opacity: .6;
        background-color: $border-color;
        border-color: $border-color;
    }
    .progress-text {
        color: black;
        transform: translateX(-40%);
        white-space: nowrap;
    }

    // box
    .onboarding-wrapper {
        border: 1px solid $border-color;
        border-radius: 0 0 $border-radius $border-radius;
    }
    .content-container {
        margin: 5% auto;
        // text-align: center;
    }

    

    // verification page
    .verification-completion {
        // width: 60%;
        // margin: 0 auto;
        text-align: center;
    }
    .verification-image {
        width: 30%;
        margin: 5% 0;
    }


    // onboarding button styling
    @media (min-width: 542px) {
        .left-button {
            text-align: right;
        }
        .right-button {
            text-align: left;
        }
        .left-button, .right-button {
            order: 1;
        }
    }
    

    // objectives page
    .choose-objectives-wrapper {
        text-align: left;
        width: 100%;
        .objectives-button-group {
            // display: inline-block;
            display: block !important;
            width: 100%;
            
            .objectives-content {
                text-align: left;
                border: 1px solid $border-color;
                width: 100%;
                background: transparent;
                margin: 5px 10px 5px 0;
                border-radius: 3px !important;
                transition: .25s; 
                font-size: $button-fs;
                padding: 15px 25px;
                text-transform: none;

                .check-mark {
                    display: inline-block;
                    border: 1px solid $border-color;
                    z-index: 100;
                    position: relative;
                    height: 15px;
                    width: 15px;
                    transition: .25s;
                    vertical-align: top;
                    margin-top: 7px;
                    svg {
                        font-size: 9px;
                        color: white;
                        margin: auto;
                        display: none;
                        margin-top: 2px;
                    }
                }
                p {
                    margin-bottom: 0;
                    display: inline-block;
                    margin-left: 20px;
                    color: #555555;
                    width: calc(100% - 35px);
                    letter-spacing: normal;

                }                
            }
            .objectives-content:hover {
                background-color: transparent!important;
                border: 1px solid $border-color !important;
                .check-mark {
                    background: $border-color;
                    
                }
            }
        
            .objectives-content:active {
                
            }
            .objectives-content.active:hover {
                // background: $primary-color !important;
                // border-color: $primary-color !important;
            }
            .active {
                .check-mark {
                    background-color: $primary-color !important;
                    border-color: $primary-color !important;
                    svg {
                        display: block;
                    }
                }
            }
            .objectives-content.active p{
                // color: white !important;
            }
            .objectives-content:focus {
                background-color: transparent !important;
                border: 1px solid $border-color !important;
            }
         
        }
        
    }

    // choose emojis page
    .choose-emojis-wrapper {
        text-align: center;
        

        .emojis-button-group {
            display: inline-block;
        }
        .emojis-content {
            border: 1px solid $border-color;
            height: 150px;
            width: 150px;
            background: transparent;
            border: 1px solid $border-color;        
            margin: 5px 10px 5px 0;
            border-radius: 3px !important;
            transition: .25s; 
            display: inline-block;
            font-size: $button-fs;
        }
        .emojis-content:hover {
            background-color: rgb(240, 240, 240) !important;
            border: 1px solid $border-color !important;
        }
    
        .emojis-content:active {
            background-color: $lighter-border-color;
            @include scale(.97);
        }
        .emojis-content.active:hover {
            background: $primary-color !important;
            border-color: $primary-color !important;
        }
        .active {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
        }
        .emojis-content.active p{
            color: white !important;
            
        }
        .emojis-content:focus {
            background-color: transparent !important;
            border: 1px solid $border-color !important;
        }
        .emojis-content-inner {
            // height: 50px;
            transform: translateY(27px);
            color: #555555;
            text-transform: none;
    
        }
        .emojis-content img {
            height: 40px;
            margin-bottom: 20px;
            font-size: 12px;
            letter-spacing: no;
        }
        .emojis-text {
            letter-spacing: normal;
            // font-weight: 1000;
            color: rgb(53, 53, 53);
        }
    }
    

    // select inerests - button is in buttons.scss
    .select-interests {
        text-align: left;
        margin-bottom: 20px;
    }
    
    #show-more-button {

    }
    .show-more-plus {
        transform: translateY(4%) scale(1.5);
        display: inline-block;
        margin-left: 6px;
    }

    // quiz questions
    .quiz-questions {
        display: inline-block;

        .radio-button {
            background: transparent !important;
            border: 1px solid $border-color !important;
            color: $darker-border-color !important;
            padding: 10px 60px;
            margin: 5px 20px 10px 0;
            border-radius: $border-radius !important;
            transition: .25s;
            width: 160px;
            // max-width: 40%;
            // display: inline-block;
        }
        .radio-button:hover {
            background-color: $lighter-border-color !important;
            // border-color: $primary-color;
            // color: white;
        }
        .radio-button:active {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
            color: white !important;
            @include scale(.97)
        }
        .active {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
            color: white !important;
        }
        .active:hover {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
            color: white;
        }
    }
    


    // info dump
    .phone-input input{
        width: 100%;
    }
    #pointer {
        cursor: pointer
    }
    .birthday-disclaimer {
        font-size: $disclaimer-fs;
        margin-top: -12px;
    }

    .personal-bio {
        p {
            margin-top: 5px;
            font-size: $disclaimer-fs;
            display: inline-block;
        }
        .good-to-go {
            color: green;
        }
        .personal-bio-length {
            p:nth-child(1) {
                display: none;
            }
            @media (min-width: 500px) {
                p:nth-child(1) {
                    display: inline-block;
                }
                p:nth-child(2) {
                    float: right;
                }
            }
            
        }
        i {
            font-size: $disclaimer-fs;
            font-style: normal;
        }
    }

}


.large-font {
    // .onboarding-progress-bar {
    //     font-size: 16px;

    // }
    button {
        font-size: 22px;
    }
    p {
        font-size: 23px;
        line-height: 28px;
    }
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 30px;
    }
    .birthday-disclaimer {
        font-size: 20px;
    }
    .personal-bio i {
        font-size: 25px;
    }
    .training-quiz-page .survey-button {
        font-size: 25px;
    }
    .personal-bio-length {
        p {
            font-size: 16px;
        }

    }
    .personal-bio-length p:nth-child(2) {
        margin-top: 10px;
    }
}





// small how it works
.small-how-it-works {
    text-align: center;
    width: 100%;
    h2 {
        margin-bottom: 70px;
    }
    img {
        height: 100px;
    }
    p {
        margin-top: 10px;
        margin-bottom: 50px;
    }
}


// training quiz
.training-quiz-page {
    #answered-correctly {
        pointer-events: none;
        opacity: .5;
    }
    #check-answer {
        svg {
            font-size: 13px;
            margin-right: 5px;
            font-size: 20px;
        }
        .correct {
            svg {
                color: green;
            }
        }
        .incorrect {
            svg {
                color: rgb(197, 57, 15);
            }
        }
    }
    h3 {
        margin-bottom: 20px;
    }
    .survey-button {
        border: 1px solid $border-color;
        border-radius: 3px;
        background: none;
        padding: 15px 30px;
        font-size: $fs-p-1;
        transition: .25s;
        display: block;
        margin: 15px auto;
        max-width: 100%;
        width: 500px;
    }
    .survey-button:hover {
        border-color: $primary-color;
        color: $primary-color;
    }
    .survey-button:active {
        @include scale(.97);
    }
    .active-sb {
        background: $primary-color;
        color: white;
        border-color: $primary-color;
    }
    .active-sb:hover {
        color: white;
    }
    #wrong-answer {
        background: rgb(197, 57, 15);
        // background: none;
        // color: rgb(197, 57, 15);
        color: white;
        border-color: rgb(197, 57, 15);
    }
    .completed-quiz {
        // border: 1px solid $border-color;
        border-radius: 3px;
        background: none;
        padding: 15px 30px;
        font-size: $fs-p-1;
        transition: .25s;
        display: block;
        max-width: 100%;
        width: 500px;
        text-align: center;
        margin-top: 50px;
        img {
            width: 500px;
            max-width: 100%;
            margin-bottom: 50px;
        }
    }
}





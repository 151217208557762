@import "utilities/variables";
// @import "onboarding";

@mixin scale($scale-amount) {
    transform: scale($scale-amount);
    -webkit-transform: scale($scale-amount); /*Chrome & Safari*/
    -moz-transform: scale($scale-amount); /* Firefox */
    -ms-transform: scale($scale-amount); /* Internet Explorer */
    -o-transform: scale($scale-amount); /* Opera */
}
@mixin buttonDefaults() {
    margin: 5px 5px;
    padding: 4px 0px;
    border-radius: $border-radius;
    transition: .25s;
    width: 240px;
    max-width: 100%;
}
@mixin buttonDefaults() {
    margin: 5px 5px;
    padding: 4px 0px;
    border-radius: $border-radius;
    transition: .25s;
    width: 240px;
    max-width: 100%;
}
button:focus {
    outline: none;
}
button:focus {
    outline: none;
}

// buttons
    
.button-wrapper {
    border-top: 1px solid $border-color;
    padding: 10px 0px;
    text-align: center;


}
.primary-button {
    @include buttonDefaults();
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: white;
}
.primary-button:hover {
    background-color: $hover-color;
    border: 1px solid $hover-color;
}
.blue {
    background-color: $secondary-color;
    border: 1px solid $secondary-color; 
}
.blue:hover {
    opacity: .8;
    background-color: $secondary-color;
    border: 1px solid $secondary-color; 
    
}

.secondary-button {
    @include buttonDefaults();
    border: 1px solid $border-color;
    color: $darker-border-color;
    background: none;
}
.secondary-button:hover {
    background-color: $lighter-border-color;
}

.tertiary-button {
    @include buttonDefaults();
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;


}
.tertiary-button:hover {
    // background-color: $primary-color;
    background-color: rgba($primary-color, .2);
    // color: white;
}

.secondary {
    border-color: $secondary-color;
    color: $secondary-color;
}
.secondary:hover {
    background: rgba($secondary-color, .2);
}

.primary-button:active, .secondary-button:active, .tertiary-button:active {
    @include scale(.97);
}

.light-button {
    // background: rgb(255, 250, 225);
    // color: rgb(175, 151, 17);
    border-radius: $border-radius;
    min-width: 200px;
    border: none;
    padding: 5px 20px;
    transition: .25s;
}
.light-button:hover {
    background: rgb(163, 163, 163);
}
.light-button:active {
    @include scale(.97);
}


.inactive-button {
    opacity: .3;
    pointer-events: none;
}

// interest button
.interests-buttongroup {
    display: block;
    .interest-button {
        border: 1px solid $lighter-border-color;
        color: $darker-border-color;
        // border: 1px solid $secondary-color;
        // color: $secondary-color;
        background: none;
        padding: 5px 25px !important;
        margin: 10px 10px 0 0 !important;
        text-transform: capitalize !important;
        border-radius: $border-radius !important;
        // @include buttonDefaults();
        letter-spacing: normal;
        line-height: 20px;
        // font-size: 16px;
        transition: .25s;
        vertical-align: middle;
    }
    .interest-button:hover {
        background-color: rgb(240, 240, 240) !important;
        border: 1px solid $secondary-color !important;
        color: $secondary-color !important;
    }
    .interest-button.active {
        // background-color: $primary-color !important;
        // border-color: $primary-color !important;
        background-color: $secondary-color !important;
        border-color: $secondary-color !important;
        // box-shadow: 0 0 15px gray;
    }
    .interest-button.active:hover {
        background-color: $secondary-color !important;
        color: white !important;
    }
    .interest-button:active {
        background-color: $secondary-color;
        color: white;
        @include scale(.97);
    }
    .interest-button p {
        margin: 0;
        display: inline-block;
    }
}



.interest-box {
    display: inline-block;
    pointer-events: none;
    background: #d2e3f5;
    color: #659edb;
    // background: #fff5d8;
    // color: #C29200;
    font-weight: bold;
    // color: white;
    padding: 5px 20px;
    margin: 5px 5px 5px 0;
    border-radius: $border-radius;
    // opacity: .5;
}

// cancel button
.cancel-button {
    background: $lighter-border-color;
    border: 1px solid $border-color;
    color: $darker-border-color;
    transition: .25s;
    letter-spacing: .2em;
    font-size: 14px;
    text-transform: uppercase;
}
.cancel-button:hover {
    background-color: rgba($border-color, .2);
}




// survey and training buttons

.survey-button {
    border: 1px solid $border-color;
    border-radius: 3px;
    background: none;
    padding: 15px 30px;
    font-size: $fs-subtitle-4;
    transition: .25s;
    display: block;
    margin: 15px auto;
    max-width: 100%;
    width: 500px;
}
.survey-button:hover {
    border-color: $primary-color;
    color: $primary-color;
}
.survey-button:active {
    @include scale(.97);
}
.active-sb {
    background: $primary-color;
    color: white;
    border-color: $primary-color;
}
.active-sb:hover {
    color: white;
}

.close-button {
    width: 50px;
    display: inline-block;
    text-align: right;
    button {
        background: none;
        border: none;
    }
    button:hover {
        svg {
            color: rgb(187, 187, 187);
        }
    }
    svg {
        transition: .25s;
        color:white;
        font-weight: bold;
    }
}
.toast-icon {
    margin-right: 15px;
    display: inline-block;
    margin-top: -5px;
}


// button that gives more information
.more-info-button {
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    transform: translateY(-15%);
    color: rgb(121, 121, 121);
    font-size: 16px;
}
@import './utilities/variables';
@import "buttons";

html {
    scroll-behavior: smooth;
}

@mixin default-card() {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin: 20px 0;
}
    @media (max-width: 462px) {
        .desktop-only {
            display: none;
        }
    }
    @media (min-width: 462px) {
        .mobile-only {
            display: none;
        }
    }
    @media (min-width: 576px) and (max-width: 1000px) {
        .container, .container-sm {
            max-width: 100%;
        }
    }
input:focus {
    outline: none;
}
.dashboard-header-wrapper {
    border-bottom: 1px solid $border-color;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 100;

}
    .dashboard-header {
        top: 0;
        position: relative;
        // padding: 0 11%;
        .navbar-brand {
            color: black;
            font-size: $fs-subtitle-3;
            margin-top: 10px;
            // margin-right: 200px;
        }
        .nav-item {
            margin-bottom: 0;
            position: relative;
            float: right;
            right: 0;
            text-align: right; 
            font-size: $fs-navbar;
            svg {
                margin-top: -5px;
            }
        }
        .login-dropdown {
            svg {
                margin-top: 0 !important;
            }
        }
        .nav-link {
            height: 100%;
            color: $darker-border-color;
            padding: 10px 0px $nav-link-padding 0px ;
            margin: 0 20px 0 0;
            transition: .25s;
        }
        @media (min-width: 462px) and (max-width: 992px){
            .nav-link {
                padding-bottom: 15px ;
 
            }
        }
        .nav-link.active {
            color: black;
            border-bottom: 2px solid black;
        }
        .nav-link:hover {
            color: black;
        }
        .dropdown {
            margin-top: 7px;
        }
        .dropdown-menu {
            background: none;
        }
        .float-right {
            right: 0;
            position: absolute;
        }
        @media (max-width: 550px) {
            .float-right {
                // display: none;
                position: relative;
                left: 0;
                svg {
                    display: none;
                }
                .nav-item {
                    white-space: nowrap;
                }

            }
            .desktop-navbar {
                display: none;
            }
            #mobile-dropdown {
                float: right;
                position: absolute;
                top: 9px;
                right: 10px;
                display: inline-block !important;
                svg {
                    color: rgb(151, 151, 151);
                    font-size: $fs-subtitle-2;
                }
            }
            .login-dropdown:hover {
                color: gray;
            }
        } 
        
        .login-dropdown {
            transition: .25s;
        }
        #mobile-dropdown {
            display: none;
        }
        
        .dropdown-menu {
            background: white;
        }
        .notification {
            width: 17px;
            height: 17px;
            background: $primary-color;
            font-size: 10px;
            color: white;
            border-radius: 50%;
            text-align: center;
            left: 65px;
            top: 12px;
            position: absolute;
        }
    }
// }

.dashboard {
    font-size: $fs-p-1;
    .btn {
        font-size: $fs-p-2;
    }
    .title {
        color: black;
        font-size: $fs-title;
        font-family: var(--font-overpass);
    }
    // text-align: center;
    margin: 90px 0%;
    @media (max-width: 550px) {
        margin: 150px 0;
    }
    .title {
        // margin-left: 7%;
    }
   
    // explore page
    .explore-page {
        
        .side-nav-bar {
            padding-right: 100px;
            width: 30%;
            display: inline-block;
            vertical-align: top;
            height: 100%;

            .fixed-positioning {
                position: fixed;
                height: 100%;
                @media (min-width: 900px) {
                    top: 50px;
                }

                .side-nav-inner {

                    position: relative;
                    @media (max-height: 500px) {
                        overflow: scroll;
                    }
                    height: 100%;
                    width: 110%;
                    @media (min-width: 900px) {
                        width: 130%;
                        padding-bottom: 100px;

                    }

                    .nav-top {
                        @media (min-width: 900px) {
                            padding-top: 30px;
                        }
                        .active {
                            color: $primary-color !important;
                            
                        }
                        
                        .nav-link {
                            font-size: $fs-subtitle-4;
                            color: black;
                            padding: 5px 0;
                        }
                        .icon {
                            font-size: $fs-subtitle-4;
                            display: inline-block;
                            margin-right: 15px;
        
        
                        }
                        .text {
                            display: inline-block;
        
                        }
                    }
                    .nav-bottom {
                        width: 80%;
                        padding-top: 10px;
                        padding-bottom: 20px;
                        .small-title {
                            padding-top: 10px;
                            color: rgb(134, 134, 134);
                        }
                        
                        .explore-match-sneakpeek {
                            margin-bottom: 30px;
                            a {
                                display: inline-block;
                            }
                            .video {
                                display: inline-block;
                                float: right;
                                // border: 1px solid $border-color;
                                // padding: 5px 25px;
                                max-width: 50px;
                                // margin-right: 15px;
                                text-align: center;
                                height: 100%;
                                margin: 0;

                                svg {
                                    text-align: center;
                                    margin: auto;
                                }
                            }
                            .chat {
                                vertical-align: top;
                                font-size: $fs-p-2;
                                max-width: 190px;
                                color: $darker-border-color;
                                svg {
                                    margin-top: -2px;
                                    margin-right: 5px;
                                }
                                
                            }
                        }
                        
                    }
                    
                }
                
            }
            
        }
        .invite-friends-link {
            bottom: 0;
            padding-right: 110px;
            padding-top: 15px;
            position: fixed;
            background: white;
            height: 75px;
            svg {
                margin-top: -3px;
            }
        }
        @media (max-width: 900px) {
            .invite-friends-link {
                padding-right: 100px;

            }

        }
        .middle {
            width: 70%;
            display: inline-block;
        }
        @media (max-width: $small-width) {
            .side-nav-bar {
                // display: none;
                position: relative;
                width: 100%;
                display: block;
                margin-top: -20px;
                margin-bottom: 10px;
                .fixed-positioning {
                    position: relative;
                }
                .nav-bottom {
                    display: none;
                }
            
            }
            .middle {
                position: relative;
                display: block;
                width: 100%;
                .share-card {
                    .form-group {
                        width: 100% !important;
                    }
                }
                .text {
                    // float: left;
                    // display: block !important;
                }
                .arrow {
                    // float: left !important;
                    // display: block !important;
                    // position: absolute;
                }
            }
            
            
        }
        .share-card {
            @include default-card();
            margin-top: 5px;
            padding: 20px 30px;
            background: rgb(248, 248, 248);
            .share-icon {
                display: inline-block;
                width: 10%;
                margin-right: 15px;

            }
            img {
                width: 50px;
                vertical-align: middle;

            }

            svg {
                font-size: $fs-xl;
                color: rgba($secondary-color, .5);
            }
            .form-group {
                cursor: pointer;
                display: inline-block;
                // width: 100%;
                vertical-align: middle;
                margin-top: 15px;
                width: 85%;
                input {
                    pointer-events: none;
                    border-radius: $border-radius !important;
                    transition: .25s;
                }
            }
            .form-group:hover {
                input {
                    background-color: rgb(241, 241, 241) !important;
                }
            }
            .modal-content {
                h1 {
                    margin: 0 !important;
                }
            }
        }
        
        .quote-card {
            @include default-card;
            margin-top: 0;
            padding: 20px 30px;
            display: inline-block;
            width: 100%;
            #share-button, #like-button {
                float: right;
                display: inline-block;
                max-width: 110px;
                svg {
                    margin-top: -2px;
                }
                
            }
            #three-dots {
                float: right;
                display: inline-block;
                max-width: 40px;

                svg {
                    margin-top: -2px;
                }
            }
            .tertiary-button:hover {
                color: $primary-color;
                background: transparent;
                border-bottom-right-radius: $primary-color;
            }
        }
        .quote {
            color: black;
        }
        #resources {

            .match-card {
                transition: .25s;
                margin-bottom: 15px;
                padding: 50px 30px;

            }
            .match-card:hover {
                // box-shadow: 0 0 10px rgb(201, 201, 201);
                background: rgb(240, 240, 240);
            }
            .match-card:active {
                @include scale(.97);
            }
            .text, .arrow {
                display: inline-block;
                color: $darker-border-color;
            }
            .arrow {
                float: right;
                font-size: $fs-title;
                margin-top: -13px;
            }
        }

        
        
        
        
    }

    // general icon by text component
    .icon-by-text {
        .icon {
            display: inline-block;
            margin-right: 15px;
            vertical-align: top;

            img {
                width: 50px;

            }
        }
        
        .text {
            display: inline-block;
            margin-top: 5px;
            .name {
                font-size: $fs-p-1;
                font-weight: bold;
            }
            .location {
                font-size: $fs-p-2;
                color: rgb(75, 75, 75);
            }    
        }
    }

    // refer page
    .refer-page {
        .invite-wrapper {

        }
        .refer-card {
            @include default-card;
            padding: 20px 30px;
            max-width: $invite-link-width;
            // display: inline-block;
            margin-right: 100px;
            vertical-align: top;
            overflow: hidden;

        }
        @media (max-width: $medium-width) {
            .refer-card {
                width: 100%;
            }

        }
        
        .refer-link {
            .form-group {
                width: 100%;
                margin-bottom: 0;
            }
            input {
                // border: none;
            }
            input:focus, input:active {
                // border: none;
            }
            p {
                float: left;
                margin: 0;
                vertical-align: middle;
                margin-top: 10px;
            }
            .copied-text {
                background: #ACCEF7;
                pointer-events: none;
            }
            #copied-copy-text {
                pointer-events: none;
            }
            
            
        }
        .refer-buttons {
            float: right;
            // position: absolute;
            // top: 0;
        }
        .copy-button {
            background: none;
            border: none;
            text-decoration: underline;
            color: rgb(121, 121, 121);
            transition: .25s;
            margin-right: 15px;
            margin-left: 0;
            text-align: left;

        }
        .copy-button:hover {
            color: black;
        }
        .share-button {
            border-radius: 3px;
            padding: 10px 15px;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: white;
        }
       
        .refer-card-hiw {
            @include default-card;
            padding: 20px 30px;
            svg {
                font-size: 50px;
                margin-bottom: 20px;
                margin-top: 10px;
            }
        }
        #include-border {
            @include default-card();
            padding: 20px 10px;
        }
        .tracking-container{
            padding-bottom: 20px;
            .tracking-card {
                @include default-card();
                padding: 20px 30px;
                display: inline-block;
                width: 98%;
                min-height: 200px;
                margin: 1% 0% 30px 0;
                // height: 100%;
                .tracking-top {
                    h3 {
                        font-size: $fs-subtitle-4;
                    }
    
                }
                .tracking-bottom {
                    // background: red;
                    svg {
                        display: inline-block;
                        // width: 30%;
                        font-size: $fs-title;
                        margin-right: 25px;
                        margin-bottom: 10px;
                        margin-top: 5px;
                    }
                    .completed {
                        color: $primary-color;
                    }
                    p {
                        display: inline-block;
                        // width: 70%;
                        vertical-align: middle;
                        margin: 0;
                        width: 80%;
                    }
                }
            }
        } 
        #email-invite {
            h1 {
                font-size: $fs-p-1;
            }
            p {
                font-size: $fs-p-2;
            }
            // background: red;
            .input-section {
                border: 1px solid $border-color;
                padding: 0 10px;
                .form-group {
                    margin: 10px 0px;
                    display: inline-block;
                    width: calc(100% - 60px);
                    input {
                    border: none;
                    }
                }
                button {
                    display: inline-block;
                    width: 60px;
                    padding: 2px 0;
                    margin: auto 0;
                    font-size: 16px;
                }
                
                
            }
            .disclaimer-section {
                .disclaimer {
                    display: inline-block;
                }
                .validation {
                    @media (min-width: 420px) {
                        display: inline-block;
                        float: right;
                        margin-right: 15px;
                        margin-top: 3px;
                    }

                }
            }
        }
        
        
        
        
        
    }
    

    // settings page
    .settings-page {
         
    }

    .settings-card {
        @include default-card;
        max-width: 800px;
    
        .card-top {
            padding: 20px 50px;
            .card-title {
                font-size: $fs-subtitle-4;
                font-weight: $fs-p-1;
            }
            .card-subtitle {
                font-size: $fs-p-2;
            }
            .card-input {
                border-radius: 3px;
                border: 1px solid $border-color;
                
            }
            .card-input:focus {
                border: 1px solid black !important;
            }
            .form-check-input {
                margin-top: 7px;
            }
            .form-check-label {
                font-size: $fs-p-2;
                margin-left: 5px;
            }
            .deletion-checkbox {
                margin: 0;
            }
        }
        .card-footer {
            padding: 6px 50px;
            vertical-align: middle;
            .left {
                transform: translateY(10px);
                float: left;
                display: inline-block;
            }
            .right {
                float: right;
                display: inline-block;
            }
            #save-button {
                float: right;
                padding: 5px 5px !important;
                max-width: 100px;
            }
        }
        .name input {
            max-width: 250px;
        }
        .email input {
            max-width: 250px;

        }
        
        .settings-edit {
            display: inline-block;
            margin-left: 15px;
            text-decoration: underline;
            background: none;
            border: none;
        }
        .avatar-field {
            img {
                width: 70px;
                margin-right: 25px;
                margin-top: 15px;
                margin-bottom: 5px;
                filter: grayscale(100%);
                cursor: pointer;
                transition: .25s;
            }
            img:hover {
                filter: grayscale(50%);
            }
            img:active {
                @include scale(1.15);
            }
            #active-avatar {
                @include scale(1.1);
                filter: grayscale(0%);
            }
            
        }
    }

    // match page
    .match-page {
        .card {
            margin: 20px 0;
        }
        
        #add-margin {
            margin: 20px 0 ;
        }
        .col {
            // margin: 5px 0;
        }
        .match-card {
            @include default-card;
            padding: 15px 30px;
            margin: 0;
        }
        .match-card-left-col {
            margin-top: 20px;
        }
        #add-match {
            text-align: center;
            margin-bottom: 20px;
            padding: 50px 20px;
            .add-match-content {
                max-width: 600px;
                margin: auto;
                h1 {
                    margin-top: 20px;
                }
                p {
                    // margin: 0;
                }
                button {
                    margin-bottom: 20px;
                }
            }
        }
        .icon-and-text {
            margin-left: 10px;
            margin-bottom: 10px;
            .icon {
                margin-right: 30px;
            }
            .name {
                font-size: $fs-subtitle-3;
            }
        }
        #video-button {
            margin: 0 0 10px 0;
            width: 100%;
            svg {
                margin-top: -3px;
            }
        }
        #match-time {
            bottom: 0;
            margin: 0;
            position: relative;
            p {
                margin: 0;
            }
            svg {
                font-size: $fs-subtitle-2;
                // transform: translateY(150%);
                color: gray;
                transition: .25s;
                float: right;
                position: absolute;
                top: 35%;

            }
            // apologies for this lol
            @media (max-width: 260px) {
                svg {
                    position: relative !important;
                    float: none;

                }
            }
            @media (max-width: 384px) {
                svg {
                    top: 35%;
                }
            }
            @media (max-width: 768px) {
                svg {
                    top: 29%;
                }
            }
            @media (min-width: 1200px) {
                svg {
                    top: 25%;
                }
            }

            svg:hover {
                color: $secondary-color;
            }
            svg:active {
                @include scale(.97);
            }
            .add-to-cal-link {
                cursor: pointer;
            }
        }
        @media (min-width: 576px) {
            #bio {
                height: 100%;
            }
        }
        @media (max-width: $medium-width) {
            
            #match-time {
                margin-bottom: 20px;
                margin-top: 10px;
            }
            
        }
        @media (max-width: 575px) {
            #bio {
                margin-top: 20px;
                // margin-bottom: 20px;
                // height: 50%;
                
            }
        }
        #confirm-button {
            margin: 0;
            width: 100%;
            display: inline-block;
            text-transform: none !important;
        }
        #confirm-button:hover {
            background-color: transparent !important;
            border-color: $border-color !important;
            color: $darker-border-color !important
        }
        #confirm-button.active:hover {
            background-color: $secondary-color !important;
            border-color: $secondary-color !important;
            color: white !important;
        }
        #meeting-confirmation {
            height: 100%;
            

        }
        #left-col {
            height: 100%;
        }
        
        #upcoming-match-card {
            margin-bottom: 20px;
        }
        // availability styles
        .meridiem {
            font-size: $fs-p-3;
        }
        
        .availability-times-wrapper {
            // text-align: center;
            // @include default-card();
            // padding: 50px 0px;
            margin: 0px 0;
            .settings-card {
                width: 100%;
                // margin: auto;
                // max-width: 700px;
                margin-top: 20px;
            }
            .card-top {
                // padding: 0;
            }
        }
        .availability-row {
            display: inline-block;
            padding: 10px 0px 10px 0;
            width: 100%;
        }
        .availability-column {
            text-align: center;
            padding: 10px 0px;
            width: 100%;
            text-align: left;
            .availability-weekday {
                vertical-align: top;
                display: inline-block;
                width: 20%;
                margin-right: 20px;
                p {
                    margin: 0;
                    padding: 12px 0 0 0;
                }
            }
           
            .interests-buttongroup {
                // styles in buttons.scss
                display: inline-block;
                width: calc(80% - 20px);
                .availability-interest-button {
                    
                }

            }
            
            
        }

        #chat {
            p {
                margin: 0;
            }
            .people-col {
                height: 470px;
                // display: inline-block;
                // min-width: 300px;
                // border-right: 1px solid $border-color;
                // padding: 10px 10px;
                // height: 100%;
                overflow: auto;


                border-right: none;
            }
            .list-group {
                padding-top: 10px;
            }
            .list-group-item {
                border: none;
                margin: 0 10px;
                padding: 20px 50px;
                transition: .25s;
            }
            .list-group-item.active:hover {
                background: rgba(0, 0, 0, .05);

            }
            .list-group-item:hover {
                background: rgba(0, 0, 0, .03);
                
                cursor: pointer;
            }
            .MessageForm {
                padding: 15px 10px;
                border-top: 1px solid rgba(0,0,0,.125);
                .form-group {
                    max-width: calc(100% - 80px);
                    width: 1000px;
                    margin-bottom: 0;
                    display: inline-block;
                    input {
                        border: none;
                        background: rgb(223, 223, 223) !important;
                        transition: .25s;
                    }
                    input:hover {
                        background: rgb(233, 233, 233) !important;

                    }
                }
                .send-button {
                    display: inline-block;
                    width: 50px;
                    margin-left: 10px;
                    button {
                        margin-bottom: 0;
                        text-transform: none !important;
                        letter-spacing: normal;
                    }

                }
            }
            .nudge-container {
                display: inline-block;
                #nudge {
                    width: 60px;
                    margin: 0;
                    background: none;
                    border: none;
                    color: black;
                    text-decoration: underline;
                    transition: .25s;
                    white-space: nowrap;
                }
                svg {
                    margin-top: 2px;
                    margin-left: 0;
                    transition: .25s;
                }
                #nudge:hover, svg:hover {
                    color: rgb(100, 100, 100);
                }

            }
            
        }
       
        
        

        .chat-component {
            @include default-card;
            height: 600px;
            width: 100%;
            position: relative;
            .chat-left {
                display: inline-block;
                min-width: 300px;
                border-right: 1px solid $border-color;
                padding: 10px 10px;
                height: 100%;
                overflow: auto;
                .chat-profile {
                    top: 0;
                    padding: 15px 10px;
                    display: block;
                    cursor: pointer;
                    border-radius: 3px;
                    transition: .25s;
                }
                .chat-profile:hover {
                    background: rgb(248, 248, 248);
                }
                .chat-profile:active {
                    background: rgb(236, 236, 236);
                }
                .active-chat {
                    background: rgb(236, 236, 236);
                }
                .active-chat:hover {
                    background: rgb(236, 236, 236);
 
                }
            }
            .chat-right {
                display: inline-block;
                position: relative;
                height: 100%;
                // left: 30%;
                width: calc(100% - 300px);
                overflow: auto;
                top: 0;
                .chat-header {
                    top: 0;
                    position: absolute;
                    font-size: $fs-subtitle-4;
                    width: 100%;
                    padding: 10px 20px;
                    background: white;
                    z-index: 9;
                    height: 60px;
                    border-bottom: 1px solid $border-color;

                    .left {
                        display: inline-block;
                        float: left;
                        // position: fixed;
                        top: 0;
                    }
                    .right {
                        display: inline-block;
                        float: right;
                        a {
                            color: gray; 
                            margin-left: 15px;
                        }
                    }
                }
                .messages {
                    overflow: auto;
                    height: 100%;
                    width: 100%;
                    padding: 70px 5px 80px 5px;
                    vertical-align: bottom;
                    position: relative;
                    // display: inline-block;
                    .one-message {
                        width: 100%;
                        p {
                            display: inline-block;
                            border-radius: 20px 20px 20px 20px;
                            padding: 5px 30px;
                            margin: 2px 0;
                        }
                    }
                    .sender {
                        text-align: right;
                        p{
                            background: #61AAFF;
                            color: white;
                        }
                    }
                    .sendee {
                        p {
                            display: inline-block;
                            background: #F3F3F3;
                            color: black;
                        }
                    }
                    
                }
                .chat-footer {
                    background: white;
                    z-index: 9;
                    bottom: 0;
                    position: absolute;
                    border-top: 1px solid $border-color;
                    width: 100%;
                    height: 70px;
                    padding: 10px 10px;
                    input {
                        border-radius: 3px;
                        border: none;
                        background: $lighter-border-color;
                        height: 38px;
                        width: calc(100% - 200px);
                        padding: 10px;
                        margin-left: 10px;
                        transition: .25s;

                    }
                    input:hover {
                        background: rgb(235, 235, 235);
                    }
                    button {
                        width: 80px;
                    }
                    #nudge {
                        padding: 0px 5px;
                        background: none;
                        border: none;
                        color: black;
                        text-decoration: underline;
                        margin-left: 10px;
                        transition: .25s;
                        white-space: nowrap;
                        svg {
                            margin-top: 5px;
                        }
                    }
                    #nudge:hover {
                        color: rgb(71, 71, 71);
                    }
                    #send {

                    }

                }
            }
        }
        .small-match-card {
            margin: 10px;
            padding: 20px 20px;
            
            @include default-card();
            .past {
                font-weight: normal;
                font-size: $fs-p-3;
                color: gray;
            }
            // .left {

            // }
            // .middle {
            //     button:nth-child(2) {
            //         margin-bottom: 0 !important;
            //     }
            // }
            // .right {
            //     @media (min-width: 768px) {
            //         height: 100%;
            //     div {
            //         height: 100%;
            //         p {
            //             margin-top: 15px !important;
            //         }
            //     }
            //     }
            // }

        }
    }

    .profile-page {
        .profile-card {
            // border: 1px solid $border-color;
            @include default-card();
            padding: 30px 20px;
            max-width: 800px;
            display: inline-block;
            color: black;
        }
        .bio-card {
            @include default-card();
            padding: 30px 20px;
        }
        .interest-card {
            @include default-card();
            padding: 30px 20px;
            b {
                display: block;
            }
            p {
                display: inline-block;
                background: rgb(205, 205, 255);
                padding: 6px 14px;
                border-radius: $border-radius;
                margin: 10px 10px 10px 0;
            }
        }
        .similar-profiles {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
    
    .add-match-page {
        
        max-width: 800px;
        h3 {
            font-size: $fs-subtitle-1 !important;
        }
        
        .add-match-card {
            @include default-card();
            // padding-right: 50px;

            // padding: 20px 10%;
            .settings-card {
                // max-width: 100%;
                text-align: left;
                border: none;
                margin-bottom: 0;
                .card-top {
                    padding-bottom: 0;
                }
                .card-footer {
                    display: none;
                }
                
                .interests-buttongroup {
                    @media (max-width: 500px) {
                        text-align: right !important;
                    }
                    // float: right;
                    right: 0;
                    .interest-button {
                        padding: 15px 20px !important;
                    }
                }
    
            }
            button {
                margin: 0;
            }
            .match-count {
                padding-right: 50px;
                text-align: left;
                margin: 30px 0px 20px 50px;
                p {
                    font-size: $fs-p-2;
                }
                .match-count-box {
                    .inactive {
                        opacity: .2;
                        pointer-events: none
                    }
                    .minus {
                        @include default-card();
                        display: inline-block;
                        padding: 2px 10px;
                        background: none;
                        transition: .25s;
                        margin: 0 6px 0 0;
                        color: $darker-border-color;
                    }
                    .minus:hover {
                        background: rgb(240, 240, 240);
                    }
                    .minus:active {
                        @include scale(.97);
                    }
                    .content {
                        @include default-card();
                        display: inline-block;
                        padding: 2px 20px;
                        margin: 6px 6px 6px 0;
                        width: 145px;
                        // background: $border-color;
                        text-align: center;

    
                    }
                    .plus {
                        @include default-card();
                        display: inline-block;
                        padding: 2px 10px;
                        background: $primary-color;
                        color: white;
                        border-color: $primary-color;
                        transition: .25s;
                        margin: 0;
                    }
                    .plus:hover {
                        background: $hover-color;
                        border-color: $hover-color;
                    }
                    .plus:active {
                        @include scale(.97);
                    }
                    
                }
                
            }
            .match-add-footer {
                margin-left: 50px;
                margin-bottom: 50px;
                button {
                    margin-top: 20px;
                    margin-bottom: 5px;
                    width: 100%;
                    max-width: 300px;
                }
                .disclaimer {
                    font-size: $fs-p-2;
                    width: 75%;
                    max-width: 300px;
                    margin-top: 5px;
                    // margin: auto;
                }
            }
            
        }
        }
        
        .survey-page {
            margin-top: -55px;
            @media (max-width: 550px) {
                margin-top: -115px;
                padding: 5px;

            }
            h1 {
                text-align: left;
            }
            text-align: center;
            .survey-card {
                @include default-card();
                padding: 60px 30px;
                @media (max-width: 550px) {
                        padding: 10px 10px;
                }
                
            }
            p {
                font-size: $fs-subtitle-2;
                color: black;
                margin-bottom: 0px;
                font-weight: bold;
            }
            .survey-question {
                margin: 50px 0;
                // margin-top: 25vh;
                .survey-button {
                    @include default-card();
                    background: none;
                    padding: 15px 30px;
                    font-size: $fs-subtitle-4;
                    transition: .25s;
                    display: block;
                    margin: 15px auto;
                    max-width: 100%;
                    width: 500px;
                }
                .survey-button:hover {
                    border-color: $primary-color;
                    color: $primary-color;
                }
                .survey-button:active {
                    @include scale(.97);
                }
                .active-sb {
                    background: $primary-color;
                    color: white;
                    border-color: $primary-color;
                }
                .active-sb:hover {
                    color: white;
                }
            }
           
            .intro {
                .subtitle {
                    font-size: $fs-p-1;
                    color: rgb(92, 92, 92);
                    margin-bottom: 20px;
                    font-weight: normal;
                }
                .expressions {
                    button {
                        background: none;
                        border: none;
                        margin: 20px;
                        transition: .25s;
                        img {
                            transition: .25s;
                            margin: 0;
                            width: 50px;
                            display: inline-block;
                            border-radius: 50%;
                        }
                        
                        p {
                            margin-top: 10px;
                            text-transform: capitalize;
                            font-weight: normal;
                            font-size: $fs-p-1;
                        }
                        .box {
                            height: 50px;
                            width: 50px;
                            position: absolute;
                            border-radius: 50%;
                            border: 1px solid transparent;
                            transition: .25s;
                            left: 15px;
                            top: 10px;
                        }
                    }
                    button:hover {
                        .box {
                            // border-color: rgb(201, 201, 201);
                        }
                        img {
                            // @include scale(1.1);
                        }
                    }
                    button:active {
                        @include scale(1.15);
                    }
                    .active {
                        @include scale(1.1);

                    }
                    .inactive {    
                        filter: grayscale(100%);
                    }
                    
                    
                }
                .inactive-wdm {
                    background: gray;
                    border-color: gray;
                }
                .inactive-wdm:hover {
                    opacity: 1 !important;
                }
            }
            .choose-emojis-wrapper {
                h1 {
                    text-align: center;
                }
                p {
                    font-weight: normal;
                    font-size: $fs-p-1;
                }
            }
            .additional-thoughts {
                padding: 30px 0px 10px 0px;
                textarea {
                    margin: 20px auto;
                    max-width: 100%;
                    width: 500px;
                    border-radius: 3px !important;
                }
            }
        }
}


// modal offering other referral options (when you click the share button)
#invite-modal {
    // width: 35%;
    button {
        text-transform: capitalize;
        letter-spacing: normal;
        // padding: 5px 30px;
        // max-width: 100px;
        font-size: $fs-p-1;
    }
    max-width: 400px;
    // max-width: 200px
    .modal-header {
        border: none;
    }
    .modal-footer {
        border: none;
        padding-top: 0;
    }

    h1 {
        margin: 0 10px 30px 10px;
    }
    .modal-body {
        padding: 0 1rem;
    }
    a {
        display: block;
        color: black;
        margin: 20px 10px;
    }
    .invite-link-wrapper {
        button, p, svg {
            transition: .25s;
            color: rgb(102, 102, 102);
        }
    }
    .invite-link-wrapper:hover  {
        button, p, svg {
            color: rgb(0, 0, 0);
            // color: $primary-color;
        }
        img {
            filter: grayscale(100%) brightness(70%);

        }

        // color: red !important; 
    }
    p {
        display: inline-block;
        font-size: $fs-subtitle-4;
        margin: 0;
    }
    svg {

        display: inline-block;
        width: 30px;
        float: right;
        margin-top: 0px;
        font-size: 40px;
    }
    img {
        filter: grayscale(100%) brightness(100%);
        display: inline-block;
        width: 30px;
        float: right;
        margin-top: 0px;
        font-size: 40px;
        transition: .25s;
    }
    hr {
        margin: 0;

    }
    .refer-link {
        button {
            background: none;
            border: none;
            font-size: $fs-subtitle-4;
            font-weight: 200;
            padding: 0;
        }
       
    }
    .refer-link:hover {
        color: red !important;
    }
    button {
        border-radius: $border-radius !important;
    }
}
@media (max-width: $small-width) {
    #invite-modal {
        max-width: 100%;

        margin: 0;
    }

}
.modal {
    button {
        border-radius: $border-radius !important;
    }
}

#story-confirmation-modal {
    top: 0;
    .modal-content {
        margin-top: -50vh;
    }
    #confirmation-modal-buttons {
        button {
            max-width: 150px;
        }
    }
}




// notification cards
.notification-card {
    @include default-card();
    padding: 20px 30px;
    position: relative;
    margin-top: 0;
    margin-bottom: 30px;
    
    
    .close-button {
        position: absolute;
        right: 20px;
        top: 7px;
        font-size: $fs-title;
        cursor: pointer;
        @media (max-width: 462px) {
            display: none;
        }
    }
    
    
}
#intro-box-wrapper {
    padding: 0px 0;
    hr {
        margin: 0;

    }
    .intro-box {
        .content-wrapper {
            font-size: $fs-p-1;
            transition: .25s;
            cursor: pointer;
            padding: 20px 30px;
            width: 100%;
            text-align: left;
            background: none;
            border: none;
            svg {
                margin-top: -2px;
            }
            p {
                margin: 0;
                display: inline-block;
                margin-left: 15px;
            }
        }
        .content-wrapper:hover {
            background: #f3f3f3;
        }
        .remove {
            pointer-events: none;
            .green-check {
                color: $primary-color;
            }
            @media (max-width: 500px) {
                .close {
                    display: none;
                }
            }
            .close {
                float: right;
                font-size: 30px;
                transition: .25s;
                pointer-events: auto;

            }
        }
        .remove:hover {
            background: white;
        }
        .active-wrapper {
            background: #f3f3f3;
            padding-bottom: 5px;
        }
        .content-collapse {
            background: #f3f3f3;
            padding: 0 61px;
            color: rgb(90, 90, 90);
            font-size: $fs-p-2;
            p {
                margin: 0;
            }
            button {
                margin: 10px 0 30px 0;
                width: 200px;
                

            }
        }
        
    }
}
.horizontal {
    background: $secondary-color;
    border-color: $secondary-color;
    position: relative;

    p {
        position: relative;

        display: inline-block;
        // margin-top: 5px;
        margin: 0;
        color: white;
    }
    a {
        display: inline-block; 
        float: right;
        position: relative;
        margin-top: -5px;
        @media (max-width: 530px) {
            float: none;
            display: block;
            margin-top: 10px;
            position: relative;
        }
    }
    button {
        background: white;
        color:  black;
        margin-bottom: 10px;
    }
    button:hover {
        background: rgb(156, 156, 156);
    }
}
.light {
    @media (max-width: 900px) {
        span {
            display: block;
        }
        span:nth-child(2) {
            float: none !important;
            margin-top: 0;
            display: block;
        }
    }
    span {
        display: inline-block;
    }
    span:nth-child(2) {
        float: right;
        margin-top: 15px;
    }

    b {
        font-size: $fs-subtitle-4;
        display: block;
    }
    p {
        margin-bottom: 0px;
    }
    button {
        max-width: 140px;
    }

}
.top {
    b {
        font-size: $fs-subtitle-4;
        // color: white;
    }
    p {
        // color: rgb(238, 236, 236);
        margin-bottom: 20px;

    }
    button {
        background: $secondary-color;
        color: white;
        margin-bottom: 10px;
    }
    button:hover {
        background: #02295faf;
    }
    text-align: center;
    // background: $secondary-color;
}